export interface MarkdownPageFrontmatter {
  slug?: string;
  date?: string;
  title?: string;
  heading?: string;
  heroImage?: string;
  transparentHero?: boolean;
  menuOrder?: number;
  pageClass?: string;
}

export interface MarkdownPage {
  html?: string;
  frontmatter?: MarkdownPageFrontmatter;
}

export interface GetSinglePageResult {
  markdownRemark: MarkdownPage;
}

export interface MultiplePageNodes {
  nodes: Partial<MarkdownPage>[];
}

export interface GetMultiplePagesResult {
  allMarkdownRemark: MultiplePageNodes;
}

export interface GraphQLQueryResult<T> {
  data: T;
  extensions: any;
}

export function extractPageMetadata(input: GetMultiplePagesResult) {
  const pages = input.allMarkdownRemark.nodes;

  return pages.filter((p) => p.frontmatter).map((p) => p.frontmatter!);
}

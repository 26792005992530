import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import {
  extractPageMetadata,
  MarkdownPageFrontmatter,
  GetMultiplePagesResult,
} from './pageQuery';

const getNavigation = graphql`
  query NavQuery {
    allMarkdownRemark {
      nodes {
        frontmatter {
          slug
          title
          heading
          menuOrder
        }
      }
    }
  }
`;

function getNavigationItems(data: GetMultiplePagesResult) {
  const metadata = extractPageMetadata(data);

  const menuItems = metadata.filter((m) => m.menuOrder);

  menuItems.sort((a, b) => {
    if (a.menuOrder! < b.menuOrder!) {
      return -1;
    }

    if (a.menuOrder! > b.menuOrder!) {
      return 1;
    }

    return 0;
  });

  return menuItems;
}

interface NavigationQueryProps {
  render: (data: MarkdownPageFrontmatter[]) => React.ReactNode;
}

export default function NavigationQuery({ render }: NavigationQueryProps) {
  return (
    <StaticQuery
      query={getNavigation}
      render={(data: GetMultiplePagesResult) =>
        render(getNavigationItems(data))
      }
    />
  );
}

import { useState } from 'react';
import NavigationQuery from '../query/navigationQuery';
import { MarkdownPageFrontmatter } from '../query/pageQuery';
import { Link } from 'gatsby';

interface NavbarProperties {
  activePageSlug?: string;
}

function ensureTrailingSlash(input?: string) {
  if (!input) {
    return ``;
  }

  if (input?.endsWith(`/`)) {
    return input;
  }

  return `${input}/`;
}

export interface NavigationProps {
  items: MarkdownPageFrontmatter[];
  activeItemSlug?: string;
}

export function Navigation({ items, activeItemSlug }: NavigationProps) {
  const [navActive, setNavActive] = useState<boolean>();

  const toggleMobileNav = () => setNavActive(!navActive);

  return (
    <nav className="navbar is-fixed-top">
      <div className="navbar-brand">
        <a className="navbar-item">
          <figure className="lci-logo image is-square">
            <img
              srcSet="/images/LCI_emblem_black_28x30.png, /images/LCI_emblem_black_56x59.png 2x, /images/LCI_emblem_black_112x118.png 4x"
              src="/images/LCI_emblem_black_28x30.png"
              alt="Lions Clubs International logo"
            />
          </figure>
        </a>
        <div className="navbar-item">Lions Club Heinola</div>
        <a
          role="button"
          className={`navbar-burger burger ${navActive ? `is-active` : ``}`}
          onClick={toggleMobileNav}
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <div className={`navbar-menu ${navActive ? `is-active` : ``}`}>
        <div className="navbar-end">
          {items.map((i, idx) => (
            <Link
              key={idx}
              to={ensureTrailingSlash(i.slug)}
              className={`navbar-item ${
                i.slug === activeItemSlug ? `is-active` : ``
              }`}
            >
              {i.title}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default function Navbar({ activePageSlug }: NavbarProperties) {
  return (
    <NavigationQuery
      render={(menuItems) => (
        <Navigation items={menuItems} activeItemSlug={activePageSlug} />
      )}
    />
  );
}

import React from 'react';

import '../css/styles.scss';
import Hero from './Hero';
import Navbar from './Navbar';
import { Helmet } from 'react-helmet';

interface LayoutProps {
  currentPage?: string;
  mainTitle?: React.ReactNode;
  contentTitle?: React.ReactNode;
  heroImage?: string;
  transparentHero?: boolean;
  children?: React.ReactNode;
  navigation?: React.ReactNode;
  containerClass?: string;
}

export default function Layout(props: LayoutProps) {
  const { children, navigation, currentPage, containerClass, ...heroProps } =
    props;

  const { contentTitle } = heroProps;

  return (
    <article>
      <Helmet
        title={
          contentTitle ? `LC Heinola | ${contentTitle}` : `Lions Club Heinola`
        }
      />
      {navigation || <Navbar activePageSlug={currentPage} />}

      <Hero {...heroProps} />

      <main className={`container is-max-desktop ${containerClass ?? ``}`}>
        {children}
      </main>
    </article>
  );
}

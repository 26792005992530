import React from 'react';
import Subtitle from './Subtitle';
import Title from './Title';

export interface HeroProps {
  heroImage?: string;
  transparentHero?: boolean;
  mainTitle?: React.ReactNode;
  contentTitle?: React.ReactNode;
}
export default function Hero({
  heroImage,
  transparentHero,
  mainTitle,
  contentTitle,
}: HeroProps) {
  return (
    <header
      className={`hero is-primary ${heroImage ? `has-background` : ``} ${
        transparentHero ? `transparent-background` : ``
      }`}
    >
      {heroImage ? <img src={heroImage} className="hero-background" /> : null}
      <div className="hero-body">
        <div className="container is-max-desktop">
          <Title>{mainTitle ?? `Lions Club Heinola`}</Title>
          {contentTitle ? <Subtitle>{contentTitle}</Subtitle> : null}
        </div>
      </div>
    </header>
  );
}
